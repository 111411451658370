.header {
	z-index: 3;
	@include padding(4px, 0px, 4px, 0px);

	&-navigation {
		&__wrap {
			width: 100%;
			display: flex;
			flex-direction: column;


			@include media-breakpoint-down(xl) {
				&.container {
					padding-right: 0;
					padding-left: 0;

					.header-navigation__nav,
					.header-navigation__inner {
						width: 100%;
					}
				}

			}

		}

		&__inner {
			display: flex;
			flex-direction: row;
			align-items: center;

			@include media-breakpoint-down(lg) {
				flex-direction: column;
			}

			&__child {
				&:first-child {
					flex: 1;
					visibility: hidden;
				}

				&:last-child {
					align-items: center;

					@include media-breakpoint-down(lg) {
						width: 100%;
						align-items: flex-start;


						@include padding(17.5px, 0px, 17.5px, 0px);

						.nav__secondary-link {
							padding-top: 0.46875rem !important;
							padding-bottom: 0.46875rem !important;
							display: block;
							width: 100%;
						}
					}

				}

				&:last-child,
				&.bg {
					flex: 1.179;
					.display-flag {
						@include width(20px)
					}
				}

				&.en {
					flex: 0.780;

					.display-flag {
						@include width(18px)
					}
				}

				@include media-breakpoint-down(lg) {
					border-bottom: 1px solid rgba(255, 255, 255, 0.1);
				}

				&__mobile {
					display: flex;
					align-items: center;
					width: 100%;
					padding-right: 60px;

					@include media-breakpoint-down(xs) {
						padding-right: 26px;
					}

					.dropdown-lang {
						height: 26px;
						display: flex;
						align-items: center;
					}
				}
			}
		}

		&__nav {
			@include media-breakpoint-up(xl) {
				border-top: 1px solid rgba(255, 255, 255, 0.1);
			}

			@include media-breakpoint-down(lg) {
				margin-top: 1.56rem;
				margin-bottom: 1.56rem;
			}

			.nav__main-link__separate-line {
				@include media-breakpoint-down(lg) {
					margin-top: 1.56rem;
					margin-bottom: 1.56rem;
					border-bottom: 1px solid rgba(255, 255, 255, 0.1);
					padding: 0 !important;
				}
			}
		}


	}

	.navbar-nav {
		@include media-breakpoint-up(lg) {
			justify-content: space-between;
		}
	}

	.input-group {
		@include padding(0px, 0px, 3px, 0px);
		align-items: center;
	}

	.form-control,
	.form-control:active,
	.form-control:hover {
		background-color: rgba(0, 0, 0, 0);
		border: none;
		box-shadow: none;
		color: rgba(255, 255, 255, 0.85);
	}

	.nav__main-link.dropdown {

		.nav-link {
			display: flex;
			align-items: center;
			position: relative;
	
			&::after {
				color: rgba(255, 255, 255, 0.85);
			}

			@include media-breakpoint-down(lg) {
				@include padding(7px, 10px, 7px, 25px);
			}

			& > div {
				line-height: 0;
			}
		}
	}

	.navbar-nav .dropdown .nav {
		&__secondary-link {
			text-transform: uppercase;
			color: $dark;
		}
	}


	@include media-breakpoint-down(lg) {

		.dropdown-toggle::after {
			border-top-color: #fff
		}

		padding-top: 13px;
		padding-bottom: 10px;

		&__container {
			align-items: flex-start !important;
			padding-right: 1.5rem !important;
			padding-left: 1.5rem !important;
		}
	}
}
