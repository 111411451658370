.display {
    font-family: $font-cormorant-regular;
    font-weight: 500;
    @include font-size(95px);
    @include line-height(70px);
    letter-spacing: -1px;
    text-transform: uppercase;

    @media (max-width: 576px) {
        word-break: break-word;
    }

    @include media-breakpoint-down(md) {
        @include font-size(62px);
        @include line-height(58px);
    }

    @media (max-width: 360px) {
        @include font-size(56px);
    }
}


.h1 {
    font-family: $font-cormorant-regular;
    @include font-size(58px);
    @include line-height(60px);
    letter-spacing: -0.01em;
    text-transform: uppercase;
    margin: 0;

    @include media-breakpoint-down(md) {
        @include font-size(30px);
        @include line-height(36px);
    }

    @media (max-width: 576px) {
        word-break: break-word;
    }
}

.h2 {
    font-family: $font-cormorant-regular;
    @include font-size(48px);
    @include line-height(52px);
    letter-spacing: -0.01em;
    color: $dark;

    @include media-breakpoint-down(md) {
        @include font-size(40px);
        @include line-height(47px);
        margin-bottom: 15px;
    }

    @media (max-width: 576px) {
        word-break: break-word;
    }

}

.h3, .h3 > .link {
    font-family: $font-cormorant-bold;
    @include font-size(30px);
    @include line-height(34px);
    margin: 0;

    @include media-breakpoint-down(md) {
        @include font-size(24px);
    }
}

.h3-light {
    font-family: $font-cormorant-regular;
    @include font-size(30px);
    @include line-height(42px);

    @include media-breakpoint-down(md) {
        @include font-size(24px);
    }
}

.h4, .h4 > .link {
    font-family: $font-cormorant-bold;
    @include font-size(28px);
    @include line-height(30px);
    margin: 0;
}

.h5 {
    font-family: $font-cormorant-regular;
    @include font-size(24px);
    @include line-height(27px);
    margin: 0;
}

.h5__sub {
    font-family: $font-cormorant-regular;
    @include font-size(20px);
    @include line-height(24px);
    margin: 0;
}

.paragraph-1 {
    font-family: $font-cormorant-regular;
    @include font-size(38px);
    @include line-height(52px);
}

.paragraph-2 {
    font-family: $font-acari-regular;
    @include font-size(17px);
    @include line-height(27px);
    letter-spacing: 0.01em;
    opacity: 0.9;

    strong {
        font-family: $font-acari-bold;
    }


    @include media-breakpoint-down(md) {
        @include font-size(15px);
        @include line-height(25px);
    }


}

.paragraph-3 {
    font-family: $font-acari-regular;
    @include font-size(15px);
    @include line-height(24px);
    letter-spacing: 0.01em;
    opacity: 0.9;
}

.subtitle {
    font-family: $font-acari-bold;
    @include font-size(18px);
    @include line-height(18px);
    text-align: center;
    letter-spacing: 0.5px;
    text-transform: uppercase;

    &__sm {
        @include font-size(14px);
        @include line-height(42px);
        text-align: center;
        letter-spacing: 0.5px;

        .link {
            @include line-height(38px);
        }
    }
}

.links {
    font-family: $font-acari-bold;
    @include font-size(14px);
    @include line-height(18px);
    letter-spacing: 0.5px;
}

.h-sup {
    font-family: $font-acari-bold;
    @include font-size(12px);
    @include line-height(16px);
    letter-spacing: 1px;
    text-transform: uppercase;
    color: rgba(39, 35, 35, 0.5);
}

.tabs,
.tab-item {
    font-family: $font-acari-medium;
    @include font-size(18px);
    @include line-height(20px);
    letter-spacing: 0.01em;
    /* #EB1D01 */
    color: $dark;

    &.active {
        color: $red;
    }
}

.terms .h4 {
    line-height: 1.4;
}

.terms {
    .paragraph-3 + .paragraph-3 {
        margin-top: 20px;
    }
}

html {
    @include media-breakpoint-down(lg) {
        //font-size: 85.7%;
    }
}

.col-count-2,
.col-count-3 {
    @include media-breakpoint-down(md) {
        column-count: 1 !important;
    }
}

.col-count-15 {
    @include media-breakpoint-down(md) {
        column-count: 4 !important;
        grid-column-gap: calc(190px / 4) !important;
        column-gap: calc(190px / 4) !important;
    }
}

.paragraphs-with-mb-25 {
    p {
        margin-bottom: 1.25rem;
    }
}
